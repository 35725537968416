import {Link} from 'react-router-dom';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
  return (
    <footer>
            <div className="container">
            <div className="footerarea">
              <div className="row">
            <div className="firstcolumn col-md-3">
                    <img src='/images/galaxy-electrical-white.png' alt="Electrical Logo"/>
                    <p>Galaxy Electrical Services Ltd. delivers premium, swift and high-quality services, 24/7. While working, we examine and solve major commercial and residential electrical issues within the first hour. With a long history of providing outstanding results at an affordable price to our customers.</p>
                    </div>
            <div className="col-md-3">
                        <h3>Coverage Area</h3>
                        <ul>
                        <li>Surrey, Hampshire</li>
                        <li> Berkshire</li>
                        <li>South West of London</li>
                        </ul>
            </div>
            <div className="col-md-3">
                        <h4>Mail</h4>
                        <p><a href="mailto:info@galaxyelectricalservicesltd.co.uk">info@galaxyelectricalservicesltd.co.uk</a></p>
                        <h4>Phone no.</h4>
                        <p><a href="tel:+447400510551">+447400510551</a></p>
                        <h4>Address</h4>
                        <p>5 Kingfield Gardens Woking GU22 9DY</p>
            </div>
            <div className="fourthcolumn col-md-3">
                    <h3>Quick Links</h3>
                        <ul>
                        <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100063663155239"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a target='_blank' href="http://www.linkedin.com/in/samar-chaudhary-777362253"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        <li><a target='_blank' href="https://www.instagram.com/invites/contact/?i=44mvu8xuasze&utm_content=q6uhib"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        <li><a target='_blank' href="https://twitter.com/landlordeicrce1?s=11&t=nLNdcf_Ykv_b9BpM1jLMtQ"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
            </div>        
        </div>
        </div>
        <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="copyright-conent col-lg-12">
                  <p className='accepted-card'>
                  <img src='/images/CARDS-ACCEPTED_PNG.jpg' alt="Card Accepted"/></p>
                <p>Copyright © 2022 GALAXY ELECTRICAL SERVICES LTD. | BEST ELECTRICAL SERVICES IN SURREY, HAMPSHIRE, BERKSHIRE, SOUTH EAST LONDON</p>
                <p><Link to="/termandconditions">Terms & Conditions</Link></p>
                <p>Website Developed by: Solworx Technologies | <Link to="https://solworx.co.uk/" target="_blank" rel="noopener noreferrer">www.solworx.co.uk</Link></p>
                </div>
                </div>
            </div>
        </div>
        </div>
    </footer>
  )
}
