import './sitebutton.css';
import { Link } from 'react-router-dom';


export default function SiteButton() {
  return (
    <>
    <Link to="/contact" className='button-link'>Get a Free Quote</Link>
    </>
  )
}
