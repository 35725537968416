import React from 'react'
import './TopBar.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

export default function TopBar() {
  return (
    <div className="topbar-box">
        <div className="container">
            <div className="row tobar-content align-items-center">
                <div className="col-md-6 text-start">
                <div className="topbar-call">Call us: <a href="tel:+447400510551">+447400510551</a></div>
                <div className="topbar-email"><a href="mailto:info@galaxyelectricalservicesltd.co.uk">info@galaxyelectricalservicesltd.co.uk</a></div>
                </div>
                <div className="col-md-6 text-end topbar-social">
                <ul>
                        <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100063663155239"><FontAwesomeIcon icon={faFacebook} /></a></li>
                        <li><a target='_blank' href="http://www.linkedin.com/in/samar-chaudhary-777362253"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        <li><a target='_blank' href="https://www.instagram.com/invites/contact/?i=44mvu8xuasze&utm_content=q6uhib"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        <li><a target='_blank' href="https://twitter.com/landlordeicrce1?s=11&t=nLNdcf_Ykv_b9BpM1jLMtQ"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
