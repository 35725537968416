import './header.css';
import SiteButton from '../sitebutton/SiteButton';

const Header = () => {
    return ( 
        <div className="header">
          <div className="container">
            <div className="row">
            <div className="header-content w-75 p-3">
            <h1>Electrical Installations & Repairs</h1>
            </div>
            <div className="herobutton">
          <SiteButton/>
          </div>
          </div>
            </div>
        </div>
     );
}
 
export default Header;