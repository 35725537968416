import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'
AOS.init({duration:2000});

export default function RCDTripping() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center inner-page-header">
          <div className="col-md-8">
                <h1>Rewiring Services</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p>As your home slowly ages, it will eventually require some repair work. And this may include having to rewire your whole house. But how do you know if your house needs rewiring? First and foremost, you can get in contact with a professional electrician who will be able to provide you with a full home inspection.</p>
<p>Alternatively, there are several warning signs that you should look out for and pay attention to. If you happen to notice one or even a combination of the following, then your home may need rewiring:</p>


<p>Benefits of our RCD tripping services:</p>
<ul>
<li>Persistent</li>
<li>Burning Smell</li>
<li>Discoloured</li>
<li>Outlets and Switches</li>
<li>Flickering</li>
<li>Lights</li>
<li>Blown Fuses</li>
<li>and Tripping Circuit Breaker</li>
<li>Outlet</li>
<li>Problems</li>
<li>You Have</li>
<li>Aluminium Wiring</li>
<li>You Have</li>
<li>Experience Electrical Shocks</li>
</ul>
<p>If you are facing any of the problems above it is time to hire our expert electricians to do the job. They know how to secure a place by eliminating all the risks. We come equipped to give complete electrical solutions through excellent services.</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/rewiring-large.jpg" alt="Rewiring Services"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
