import React from 'react'
import { useEffect, useRef } from 'react';

export default function TrustPilot() {

    // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
// When it is, it will automatically load the TrustBox.
if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
<>
<div className="container">
<div className="row">
  <div className="col-lg-12">
<h2 className='text-center'>TrustPilots</h2>
<div
  ref={ref} // We need a reference to this element to load the TrustBox in the effect.
 className="trustpilot-widget" data-locale="en-US" data-pid="b5167179c03e50ec942cb" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5f8064dba7c6e8000169ad1c" data-layout-id="13" data-style-height="130px" data-style-width="100%" data-theme="light" data-stars="4,5"
>
  
  <a href="https://uk.trustpilot.com/review/landlordeicrcertificate.co.uk" target="_blank" rel="noopener"> Trustpilot
  </a>
</div>
</div>
</div>
</div>
</>
  )
}
