import React from 'react'
import { useEffect } from 'react';

export default function Blog() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <h1>Blog</h1>
            </div>
        </div>
    </div>
    </>
  )
}
