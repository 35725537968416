import './contact.css';
import ContactForm from './ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className="contact-area">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1>Contact Us</h1>
            <p>Please kindly fill the below form and our member of the team will contact you as soon as possible.</p>
            <ContactForm/>
          </div>
          <div className="col-md-6 contact-info">
            <h1>Get a Free Quote in less than 24hrs</h1>
            <p><FontAwesomeIcon icon={faPhone} /><a href="tel:+447400510551">Call (+44)7400510551</a></p>
            <p><FontAwesomeIcon icon={faAddressCard} />5 Kingfield Gardens Woking GU22 9DY</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
