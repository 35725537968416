import './navbar.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react'

const SiteNavbar = () => {

  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const showDropdown = (e)=>{
    if (window.innerWidth < 960) {
      setShow(!show);
    }else
    {
      setShow(!show);
    }
  }
  const hideDropdown = e => {
    if (window.innerWidth < 960) {
        setShow(false);
    }else
    {
      setShow(false);
    }
  }
    return (  
        <>
      <Navbar bg="light" expand="lg" expanded={expanded}>
      <Container>
      <div className="logo-area">
        <Link className='navbar-brand' onClick={() => setExpanded(false)} to="/"><img src='/images/galaxy-electrical-logo.png' alt="Galaxy"/></Link></div>
        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className="justify-content-end">
            <Link className="nav-link" onClick={() => setExpanded(false)} to="/">Home</Link>
            <Link className="nav-link" onClick={() => setExpanded(false)} to="/about">About</Link>
            <NavDropdown title="Services" id="basic-nav-dropdown"
             show={show}
             onMouseEnter={showDropdown} 
             onMouseLeave={hideDropdown}
            >
              <ul className='first-column column'>
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/appliances-installation">Appliances Installation</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/domestic-and-commercial-epc">EPC</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/eicr">EICR Testing</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/ev-charger">EV Charger Installation</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/fuse-board-changes">Fuse Board Change</Link></li>
              </ul>
              <ul className='second-column column'>
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/lighting-installation">Lighting Installation</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/portable-appliances-testing">PAT Testing</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/power-shower">Power Shower</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/rcd-tripping">RCD Tripping</Link></li>
              <NavDropdown.Divider />
              <li><Link className="dropdown-item" onClick={() => setExpanded(false)} to="/services/rewiring-services">Rewiring Services</Link></li>
              </ul>
            </NavDropdown>
            <Link className="nav-link" onClick={() => setExpanded(false)} to="/blog">Blog</Link>
            <Link className="nav-link" onClick={() => setExpanded(false)} to="/contact">Contact Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </>
     );
}
 
export default SiteNavbar;