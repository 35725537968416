import './GoogleReviews.css'
import React from 'react'


export default function GoogleReviews() {

  
  return (
    <>
    <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <h2>Google Reviews</h2>
    <div id="google-reviews"></div>
    </div>
    </div>
    </div>
    </>
  )
}
