import Header from '../../header/Header';
import { Suspense, lazy } from 'react';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import Calltoaction from '../calltoaction/Calltoaction';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TrustPilot from '../../TrustPilot';
import { useEffect } from 'react';
import GoogleReviews from '../../googlereviews/GoogleReviews';
import Video from '../../video/Video';
const Services = lazy(() => import ('../services/Services'));

AOS.init({duration:2000});

// const BaseLayout = (props) =>{
//   return ( 
//     <div className="contaier">
//     <div className="sometext">Header</div>
//     {props.children}
//    <div className="footer">Footer</div>
//     </div>
//   )
// }
export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="homepage">
        <Header/>
        <div className="partner-logo" data-aos="zoom-in">
          <div className="container">
            <div className="row">
              <div className="col-log-12">
                <ul className="logo-list">
                  {/* <li><img className='rounded' src='./images/checkatrade.jpg' alt="Checkatrade"/></li> */}
                  <li><img className='rounded' src='./images/niceic-logo.jpg' alt="Niceic"/></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className="row">
        <div className="welcome-section col-lg-12" data-aos="fadeInUp">
          <h2>Welcome to Galaxy Electrical Services Ltd.</h2>
          <p>Welcome to Galaxy Electrical Services where we provide the expertise of skilled electricians throughout Surrey, Hampshire, Berkshire and South West of London. We focus on ensuring complete client satisfaction by dedicating ourselves to delivering top-quality domestic and commercial electrical services. To enforce this assurance further, we are registered, NICEIC-approved contractors. This means our work is carried out under the latest rules and regulations, abiding by all set standards. We also hold and follow strict health and safety practices inside a place, which makes our work secure.</p>
        </div>
        </div>
        </div>
        <div className="container">
        <div className="support-section"  data-aos="zoom-in">
        <div className="row">
          <div className="col-md-3">
          <img src='./images/support.jpg' alt=""/>
            <h3>24/7 Local Electrician Services</h3>
            <ul>
            <li>We offer 24/7 electricians services to you.</li>
            <li>Fast Response from 30 minutes onwards in our area of coverage.</li>
            <li>All our team members are fully qualified and experienced.</li>
            </ul>
          </div>
          <div className="col-md-3">
          <img src='./images/installation-expert.jpg' alt=""/>
            <h3>Installation Experts</h3>
            <ul>
            <li>Highly qualified & Experienced installation teams</li>
            <li>NICEIC Accredited</li>
            <li>All works warrantied for peace of mind</li>
            </ul>
          </div>
          <div className="col-md-3">
          <img src='./images/efficient-reliable.jpg' alt=""/>
            <h3>Efficient & Reliable</h3>
            <ul>
            <li>Enquiry response Between 2-4 hours</li>
            <li>Desktop or video survey available</li>
            <li>Same day installation</li>
            </ul>
          </div>
          <div className="col-md-3">
          <img src='./images/user-friendly.jpg' alt=""/>
            <h3>Friendly & Professional</h3>
            <ul>
            <li>Fantastic customer service</li>
            <li>Helpful and informative advice</li>
            <li>High-quality aftercare</li>
            </ul>
          </div>
        </div>
        </div>
        </div>
        <div className="services-list-area"  data-aos="slide-up">
          <div className="container">
            <div className="services-list row">
          <h2>Services</h2>
            <ul>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Fuse board change </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />EICR</li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />EV Chargers </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Lighting Installation </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Electrical Emergency </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Circuit Breaker Replacement</li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />RCD Tripping</li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Power Shower</li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Electrical Installation </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Appliances Installation </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Socket & Switches Repair </li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Domestic & Commercial EPC</li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Electrical House Rewiring</li>
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Electrical Inspecting & Testing</li> 
              <li><FontAwesomeIcon icon={faArrowAltCircleRight} />Portable Appliances Testing </li>
              </ul>
              </div>
          </div>
        </div>
        <Suspense fallback="Please Wait....">
        <Services test={false}/>
        </Suspense>
        <Video/>
        <TrustPilot/>
        <div className="container">
          <div className="row" data-aos="zoom-in">
            <div className="col-lg-12">
          <h2>Providing Electrical Services 24/7</h2>
          <p>The electricians working with us have all the pertinent knacks and are drilled to the highest standards. After getting a call, we make sure that your premises are electronically safe for anyone who comes in contact with them.</p>
          <p>We use the equipment and procedure to meet all the visual and electronic national security requirements. Our electricians at Galaxy Electrical Services are experts in maintenance work with an edge in dealing with both commercial and residential properties. We aspire to yield the best results to exceed all expectations.</p>
            </div>
            </div>
        </div>
        
        <GoogleReviews />
        <Calltoaction/>
    </div>
  )
}
