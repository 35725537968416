import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'

AOS.init({duration:2000});

export default function RCDTripping() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center  inner-page-header">
          <div className="col-md-8">
                <h1>RCD Tripping</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p>RCDs (residual current device) is an instrument that quickly breaks up an electrical circuit to control and prevent serious harm during an electric shock. Since our Local electricians are the most reputable in Surrey, Hampshire, Berkshire and South West of London for providing emergency and regular electrical services, you can count on us for RCD too. It's not a big deal for our experts since they have been doing this for ages. They make sure everything is sorted safely in your property on electrical grounds.</p>

<p>Benefits of our RCD tripping services:</p>
<ul>
<li>Fully Accredited and Certified– with NICEIC and more.</li>
<li>Free Remedial Quote – Get a complete remedial quote for faults and errors.</li>
<li>Broader Coverage – We service Surrey, Hampshire, Berkshire and South West of London, domestic and commercial. </li>
<li>Expert Electricians– Our competent team of electricians is experts in identifying potential problems.</li>
</ul>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/rcd-tripping-large.jpg" alt="RCD Tripping"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
