import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

AOS.init({duration:2000});


export default function EVCharger() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center inner-page-header">
          <div className="col-md-8">
                <h1>EV Charger Installation</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p> People are investing in the environment and saving carbon prints. Now vehicle companies are also contributing to this noble cause and manufacturing electric cars. It is a genuine option for those worried about the emission of dangerous gases. So, an electric car is an excellent investment for people worried about carbon footprints. These cars come in many shapes, sizes and models with one thing in standard, EV chargers.</p>
<p>At Galaxy Electrical Services Ltd., we propose a full-service answer for all your electric vehicle charging requirements. We offer complete charging solutions for domestic and commercial intents, including hardware, software and installation. We will install your EV charger to a proper permanent structure and allow up to 10 metres of cable between your charger and car electrical distribution board.</p>
<p>There is no better time to invest in electric cars due to the falling cost of electric vehicles, improved infrastructure, and new government lead policies and inducements. Our experienced electricians can perform a complete inspection beforehand to see the possibility of a charging point at your place. We are certified to install an EV charger perfectly and safely.
</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/evc-charger.jpg" alt="Appliances Installation"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
