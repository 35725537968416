import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

AOS.init({duration:2000});


export default function FuseBoardChanges() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center inner-page-header">
          <div className="col-md-8">
                <h1>Fuse Board Changes</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p> The fuse board in any commercial and residential space is an integral tool for supplying electricity. Old fuse boards can be hazardous. They can provoke house fires and electrical shocks. We always recommend fuse board replacement as one of the precautions that should be taken during construction. At Galaxy Electrical Services Ltd., we intend to ensure the safety of our clients with the presence of safe fuse boxes.</p>
       <h2>Safe Fuse board replacement</h2>
<p>Our proficient electricians can recommend the best upgrade for your home's and office's fuse box or fuse board. They carefully install it and run the basic tests to ensure its safety.
</p>
<h2>Why should you replace a fuse board?</h2>
<p>Fuse board replacement is essential and very crucial when the box is not working correctly. What is also important is to hire experienced, equipped and skilled electricians for this job. Here is why you need to replace a damaged or old fuse board at your place: 
</p>
<strong>Protection from fire</strong>
<p>Electrical flaws are the reason for most house and office fires within the UK. Many of these fires also connected directly to a flawed fuse board. Consequently, having a replacement or even an upgrade is paramount to safety. 
</p>
<strong>Protection from electric sock</strong>
<p>Most property owners with 18th editions or better consumer units get to benefit from RCD (residual current device). This device is designed to save a life by preventing any fatal electric shock. It also protects against electric fires. Therefore, consider replacing your old fuse board with a modern box and live in a protected home.</p>
 

        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/fuse-board-changes.jpg" alt="Fuse Board Changes"/>
          </div>
          <div className="col-lg-12" data-aos="zoom-in">
          <h2>What does the process entail?</h2>
<p>Our electricians usually take around a day to replace the fuse box or fuse board. They carry out different tests to ensure its proper working. Meanwhile, replacing an old fuse box with a modern one is very cost-effective. Before changing the unit, our expert electricians make sure that the distributor's equipment at the start and the earthing and bonding capitulate with safety regulations. If the necessary components are unsafe, all the faulty elements need replacement. After completing everything, we run a series of necessary tests to mark safety.</p>
<p>If you find that your current fuse board has reached its capacity, call us freely and anytime to upgrade or repair the fuse box or fuse board at your place. We provide fuse box replacement and repair services to both residential and commercial clients. 
</p>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
