import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'

AOS.init({duration:2000});

export default function PortableAppliancesTesting() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center  inner-page-header">
          <div className="col-md-8">
                <h1>Portable Appliances Testing - PAT Services</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p>We specialise in PAT. This service will let you determine the safety of portable appliances. We provide landlords, hotels, residential homes, schools, shops, and many other private and public institutions. Our PAT process is very professional and starts with a thorough visual inspection of appliances to assess any components' signs of overheating or damage. After that, we check the fuse and tighten all wire connections. Then, our electricians run a series of tests on the appliance to ensure it is properly earthed and insulated. We assure you peace of mind and a robust solution at local electricians.</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/pat.jpeg" alt="Portable Appliances Testing "/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
