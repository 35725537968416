import React from 'react'
import { useEffect } from 'react';

export default function TermAndConditions() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Terms and Conditions</h1>

                        <p>Here at Galaxy Electrical Services Ltd we provide emergency/fast response repair and maintenance services via experienced, fully qualiﬁed and vetted engineers.</p>
                        <p>Our head oﬃce address: Galaxy Electrical Services Ltd, 77 Brookfield, Woking GU21 3AB, UK.</p>

                        <p>It is our intention that all the terms of the agreement between us are contained in this document. If you have any questions about any of the provisions included in these terms and conditions, please let us know.
                        </p>

                        <h3>Appointment Booking Process</h3>
                        <ul>
                            <li>"We will; obtain details of the works to be carried out.
                            </li>
                            <li>We will; conﬁrm the estimated cost for attendance at the time of booking.</li>
                            <li>We will; conﬁrm a suitable time frame for the engineer to attend.</li>
                            <li>We will; conﬁrm the works to be undertaken, estimated costs and attendance timeframe via email.</li>
                            <li>We will; require a credit/debit card at the time of booking to secure your appointment.</li>
                            <li>We will; debit your card on completion of the appointment (unless this is a further works appointment where a deposit can be taken in advance of attendance with your approval).</li>
                        </ul>
                        <h3>Our Fees</h3>
                        <p>Our charges are based on either an hourly rate, or ﬁxed rate fee depending on the nature of works to be undertaken.</p>
                        <p>All quotations are exclusive of VAT. This is conﬁrmed at the initial point of booking and in your booking conﬁrmation email.</p>
                        <ul>
                            <li>Hourly rate:
                                <p>This charge is deﬁned as undertaking diagnostics and/or labour being completed only. This price does not include any material that may be required, this will be at an additional cost.<br />
                                    This charge is set at a minimum of a one hour fee, any additional time that may be required above the initial one hour will be charged as an additional hour.</p>
                            </li>
                            <li>Fixed rate:
                                <p>Some services/works may be set at a ﬁxed charge, this is agreed at time booking. This price does not include any material that may be required, this will be at an additional cost.<br />
                                    Please note that some works such as site surveys can be carried out remotely using drones/satellite imaging, therefore in such scenarios you may not physically see an engineer on site, however a full report will be provided.</p>
                                <p>Quotations are valid for 30 days.</p>
                            </li>
                        </ul>
                        <h3>Payments</h3>
                        <p>Card details are taken in advance of the booking, this is to safeguard our engineers from carrying cash and so not to put them at risk. It is important that all transactions are securely managed within the head oﬃce.</p>
                        <h3>Failure To Collect Payment</h3>
                        <p>The total due on the invoice is payable immediately after the engineer has completed the site. If you do not pay the total due immediately this could lead to further late payment charges being applied.</p>
                        <strong>Late payment fees:</strong>
                        <p>Charges are 8% of the invoice amount plus the Bank of England's base rate. The Bank of England's base rate is subject to change, so the amount of interest charged will depend on the due date of your invoice and the base rate at the time.</p>
                        <p>If you do have any challenges, it is very important to get in contact to discuss the matter with us to prevent such action being taken. We may be able to oﬀer payment plan options. You can contact us via at the following methods:</p>
                        <ul>
                            <li>You can call us on: 07400 510551.</li>
                            <li>You can email us at: <a href='mailto:info@galaxyelectricalservicesltd.co.uk'>info@galaxyelectricalservicesltd.co.uk .</a></li>
                        </ul>
                        <p>Our credit control team is available Monday – Friday, 9am – 5pm.</p>
                        <h3>Appointment Time Frames</h3>
                        <p>We will make every eﬀort to arrive within the allotted time frame, however we are not liable for any delay or failure to carry out labour/diagnostics due to an event outside our control i.e.: traﬃc, poor weather conditions, or the failure of private or public telecommunications. Should we experience any challenges we will be in contact with you to make you aware.</p>
                        <h3>Access & Safety</h3>
                        <p>You must ensure the environment is safe for the company and its representatives for the purpose of carrying out the work. You must ensure you, or an authorised person is onsite to provide access. Failure to ensure safety and access could result in services not being undertaken and full charge for the service will be debited as a result.
                        </p>
                        <h3>Permits, Licenses, Regulations And Other Consents</h3>
                        <p>It is the customer’s duty to ensure suitable permission, permits, licenses and all other consents from the owner/landlord/agent/organisation, and/or planning permission if necessary is obtained prior to works carried out by the company. The
                            Galaxy Electrical Services Ltd, and or sub-contractors will not be held responsible, or liable if this has not been obtained.
                        </p>
                        <h3>Cancellation Policy & Right To Waiver</h3>
                        <p>Should you no longer require our services, or need to reschedule it is very important to make us aware immediately so not to be charged. Should the engineer arrive on site at the date/time conﬁrmed the full amount that was quoted within your telephone call/booking email conﬁrmation will still be debited.</p>
                        <p>Under the terms of the Consumer Rights Act 2015, you have the right to cancel within 14 calendar days starting on the date you agree/sign the contract, during which time you may cancel the contract without paying a penalty. This is known as the ‘cooling oﬀ period’. The law that governs the length of ‘the cooling oﬀ’ period is the Consumer Contract (Information, Cancellation and Additional Charges) Regulations 2013 and the Consumer Rights Act 2015. However, due to the nature of our business, work begins within the cooling oﬀ period meaning that you will be liable for payment.</p>
                        <p>Less than 24 hours notice – We reserve the right to charge for the full price of your appointment.</p>
                        <h3>Supplying Materials</h3>
                        <p>If you are supplying materials it is important that they are suitable to carry out the work. In the event that they are not suitable and we are unable to use them to complete the works required you will still be charged the full amount for the service.</p>
                        <h3>Guarantees & Warranty</h3>
                        <p>Workmanship guarantee provided by the company shall be for labour only, this commences from the date of completion to the 30th day only. Any parts, equipment or components supplied by the company will be covered by their respective manufacturer’s warranty.</p>
                        
                        <p>The company’s guarantee will become null and void if the work/appliance supplied by the company is:</p>
                        <ul>
                            <li>Subject to misuse or negligence.</li>
                            <li>Repaired, tampered with or modiﬁed by anyone other than a company operative/representative. The company accepts no liability for (or guarantee suitability for) materials supplied by the customer or other third parties, and will not accept liability for any damage or faults as a result.</li>
                            <li>The company is unable to guarantee any work which has been undertaken on instruction by the customer, against the companies/operatives advice/recommendations.</li>
                            <li>Any work carried out on behalf of the company by agents or subcontractors will be guaranteed under their own respective policies.</li>
                            <li>The company will not be liable or responsible for any damage or defect arising from work not fully guaranteed or where recommended work has not been carried out.</li>
                            <li>The company will not guarantee work where the customer has been notiﬁed either verbally or in writing by the company of any related work which requires attention. This also includes any temporary repairs.</li>
                            <li>The customer shall be solely liable for any hazardous situation in respect of Gas Safe regulations, or gas warning notice issued, unless otherwise caused by our Gas Safe operative.</li>
                            <li>All Gas Safe engineers operate under their own Gas Safe registration, and as such are solely responsible for any gas related work and subsequent liability.</li>
                        </ul>
                        <h3>Defective Workmanship</h3>
                        <p>Subject to the exclusions listed below, the company undertakes to make good and repair any defect in completed work, which appears within 30 days of the completion date of the same, to the extent that such defect arises from the breach of the company’s obligations under this contract.</p>

                        <p>All defects must be notiﬁed to the company by the customer within this period, and the company, or sub-contractors and its insurers must be provided the opportunity to inspect the work and any alleged defect.</p>
                        <p>This inspection shall only apply to work carried out and completed by the company that has been paid in full by the customer.</p>

                        <p>Following the inspection and it transpires the alleged defect is not the result of any work or service carried out or provided by the company or is a diﬀerent issue of that documented on the original booking the company reserves the right to make a charge to the customer for the inspection visit at its standard rate.</p>
                        <p>The company reserves the right to not carry out any work where the customer cannot provide suﬃcient evidence that the work was originally carried out by the company, or where full payment has not been received for said work.</p>
                        <p>Exclusions are:</p>
                        <ul>
                            <li>Any parts or materials supplied by the company will only be provided with the manufacturers or suppliers guarantee and are not guaranteed by the company.</li>
                            <li>Any defects resulting from the misuse, willful act or faulty workmanship by the customer or any other third party working for or under the direction of the customer.</li>
                            <li>Any structural defects, such as but not limited to subsidence and its resultant eﬀect.</li>
                            <li>Any damage to drainage systems caused by any outside force or root penetration.
                                <ul>
                                    <p>Should you ﬁnd any issues with works undertaken please contact us immediately via the following contact methods:</p>
                                    <li>You can call us on: 07400 510551.</li>
                                    <li>You can email us at: <a href="mailto:info@galaxyelectricalservicesltd.co.uk">info@galaxyelectricalservicesltd.co.uk.</a></li>
                                    <li>Alternatively, you can write to us: Galaxy Electrical Services Ltd, 77 Brookfield, Woking GU21 3AB, UK</li>
                                    <p>Our customer support team is available Monday – Friday, 9am – 5pm.
                                    </p>
                                </ul>
                            </li>
                        </ul>
                        <h3>Force Majeure</h3>
                        <p>The company will use all reasonable eﬀorts to carry out and complete the works on time, but shall not be liable to the customer or any third party if the works prove impossible due to events or circumstances beyond the company’s reasonable control.</p>
                        <h3>Disputes</h3>
                        <p>Please do get in touch if you have any concerns. You can contact us via at the following methods:</p>
                        <ul>
                            <li>You can call us on: 07400 510551.</li>
                            <li>You can email us at: <a href="mailto:info@galaxyelectricalservicesltd.co.uk">info@galaxyelectricalservicesltd.co.uk.</a></li>
                            <li>Alternatively, you can write to us: Galaxy Electrical Services Ltd, 77 Brookfield, Woking GU21 3AB, UK</li>
                            <p>Our initial response time to acknowledge your dispute is within 72hrs (Monday – Friday).</p>
                            <p>Our customer relations team is available Monday – Friday, 9am – 5pm.
                            </p>
                        </ul>
                        <h3>Call Recording</h3>
                        <p>Calls may be recorded for training and monitoring purposes.</p>
                        <h3>Data Protection</h3>
                        <p>All personal information that we may use will be collected, processed, and held in accordance with the provisions of EU Regulation 2016/679 General Data Protection Regulation "GDPR" and your rights under the GDPR. For complete details of our collection, processing, storage, and retention of personal data including, but not limited to, the purpose(s) for which personal data is used, the legal basis or bases for using it, details of Your rights and how to exercise them, and personal data sharing (where applicable), please refer to our privacy policy.</p>
                        <h3>Changes To Terms And Conditions</h3>
                        <p>Galaxy Electrical Services Ltd may change these terms and conditions of use from time to time without notice. You should check these terms and conditions regularly. Your continued use of this website will be deemed to be acceptance of the updated or amended terms.</p>
                        <h3>Governing Law</h3>
                        <p>These terms and conditions are governed by and construed in accordance with the laws of England and Wales.</p>

                        <p>Any dispute you have which relates to these terms and conditions, or your use of Galaxy Electrical Services Ltd (whether it be contractual or non-contractual), will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
