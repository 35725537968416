import React from 'react'
import './Video.css'

export default function video() {
  return (
    <div className='ratio ratio-16x9 video_container'>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/dbDg6xSaGKA?si=Dva6kxkqfEstPhzD" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
  )
}
