
import './App.css';
import TopBar from './topbar/TopBar';
import SiteNavbar from './navbar/Navbar';
import Home from './pages/home/Home';
import About from './pages/about/About';
import AppliancesInstallation from './pages/services/AppliancesInstallation';
import DomesticAndCommercialEpc from './pages/services/DomesticAndCommercialEpc';
import EICR from './pages/services/EICR';
import EVCharger from './pages/services/EVCharger';
import FuseBoardChanges from './pages/services/FuseBoardChanges';
import LightingInstallation from './pages/services/LightingInstallation';
import PortableAppliancesTesting from './pages/services/PortableAppliancesTesting';
import PowerShower from './pages/services/PowerShower';
import RCDTripping from './pages/services/RCDTripping';
import RewiringServices from './pages/services/RewiringServices';
import Contact from './pages/contact/Contact';
import Blog from './pages/blogs/Blog';
import Footer from './footer/Footer';
import TermAndConditions from './pages/TermAndConditions';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";



function App() {
  return (
    <Router>
      <TopBar />
      <SiteNavbar/>
       <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={ <About/>} />
      <Route path='/services/appliances-installation' element={ <AppliancesInstallation/>} />
      <Route path='/services/domestic-and-commercial-epc' element={ <DomesticAndCommercialEpc/>} />
      <Route path='/services/eicr' element={ <EICR/>} />
      <Route path='/services/ev-charger' element={ <EVCharger/>} />
      <Route path='/services/fuse-board-changes' element={ <FuseBoardChanges/>} />
      <Route path='/services/lighting-installation' element={ <LightingInstallation/>} />
      <Route path='/services/portable-appliances-testing' element={ <PortableAppliancesTesting/>} />
      <Route path='/services/power-shower' element={ <PowerShower/>} />
      <Route path='/services/rcd-tripping' element={ <RCDTripping/>} />
      <Route path='/services/rewiring-services' element={ <RewiringServices/>} />
      <Route path='/blog' element={<Blog/>} />
      <Route path='/termandconditions' element={<TermAndConditions/>} />
      <Route path='/contact' element={<Contact/>} />
      </Routes>
      <Footer/>
    </Router>
   
  );
}

export default App;
