import './calltoaction.css';
import SiteButton from '../../sitebutton/SiteButton';

export default function Calltoaction() {
  return (
    <div className="calltoaction">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
            <h2>Got an Electrical Project or Problem?</h2>
            </div>
            <div className="col-md-4">
            <SiteButton />
            </div>
            </div>
        </div>
    </div>
  )
}
