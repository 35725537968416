import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

AOS.init({duration:2000});


export default function LightingInstallation() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center inner-page-header">
          <div className="col-md-8">
                <h1>Lighting Installation</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p>Whether you are looking to install downlights throughout your property, hang up a chandelier or just replace a broken light – we can help! Our prices are very competitive and we charge by the hour, please see our rates. If the job is slightly larger for example installing down lights throughout a property or a set room please get in contact and we can give you a tailored quote for your project.</p>
<p>You can schedule a registered installation of electric systems at your place, followed by extension and adaptation of all the parts. We skillfully manage circuit breakers and boards, earthing points, fuse boards, fire alarms, transformers and structured cabling.</p>

<h2>Electric systems and fittings</h2>
<p>As experienced electricians, we offer not only services but expert advice on the maintenance of your house's electric system. We ensure that all the electrical appliances at your place are PAT tested. Our highest standards help us to provide outstanding electrician services at competitive rates.</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/Lighting-Installation-large.jpg" alt="Fuse Board Changes"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
