import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'

AOS.init({duration:2000});

export default function AppliancesInstallation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center inner-page-header">
          <div className="col-md-8">
                <h1>Appliances Installation</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
        <p>If you're having an appliance delivered we can connect it up for you.</p>
        <ul>
          <li>Cookers</li>
          <li>Cooker Hoods</li>
          <li>Hobs</li>
          <li>Washing machines</li>
        </ul>
        <p>For this service we charge per hour (these services very rarely take longer than an hour) please see our hourly rates.</p>
        <p>You might want to install the home appliances by yourself after getting a higher quote from some electricians. However, it is not the case with us. We give competitive market rates and quote a reasonable price for our services. Moreover, it is known that DIY is sometimes not worth the pain, and registered professionals can carry out any task expertly. Therefore, we always recommend people not install their expensive household items but instead hire professional electricians to do the job. Galaxy Electrical Services Ltd. offers top-quality services and affordable domestic and commercial installation plans. </p>
        <p>From connecting your new LED, fridge, dishwasher and washing machine to installing bathroom and bedroom appliances, we are your trusted partner for quick and reliable service. </p>
        <p>You will have everything installed by certified professionals to retain the warranty. Here, we manage bigger and smaller appliances and show you their work once the job is done.</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/appliances-installaions.jpg" alt="Appliances Installation"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
