import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

AOS.init({duration:2000});


export default function EICR() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center  inner-page-header">
          <div className="col-md-8">
                <h1>EICR Testing</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p> It is a standard document produced to follow an examination of the electrical installation within a property. It must be done by a trained, competent electrician or authorised contractor. Usually, this implies they will have taken a course encircling routine inspection and testing and are registered with authorities such as the NICEIC etc.</p>
<p>To avoid all kinds of damages to property and individuals, Galaxy Electric Services Ltd. has certified services to perform your home's EICR. We give:</p>
<strong>Residential EICR</strong>
<p>Experts in all types of domestic electrical jobs. Our friendly and reliable electricians can carry out everything from full rewiring to simply changing a light fitting.</p>
<strong>Residential EICR (Urgent or Same day)</strong>
<p>If you urgently require an EICR for your home, we are available. Our staff can reach your location in no time and perform the service.</p>

<strong>Commercial EICR</strong>
<p>Our aim is to provide businesses with comprehensive electrical installation services and routine and preventative maintenance.</p>
<strong>Commercial EICR (Urgent or Same day)</strong>
<p>Our experts are available the same day to perform Urgent EICRs for commercial buildings. All you need to do is state your requirements, like time and address, and we will be at the location in no time. </p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/EICR-Testing-.jpeg" alt="Appliances Installation"/>
          </div>
          <div className="col-lg-12" data-aos="zoom-in">
          <h2>What is the purpose of EICR?</h2>
<p>An EICR has these primary intents:</p>
<p><strong>Confirm that all electrical installations on the property are secure:</strong> Our skilled electricians will check the electrical system to ensure it isn't damaged and deteriorated. They also determine the proper structure of equipment inside a property. </p>
<p><strong>Determine damage that might impact the protection of people:</strong> Once cases are detected, our electrician will suggest curative action to ensure the proper working condition of an electrical system.</p>
<p><strong>Find elements of an electrical installation that do not meet the legal safety criteria:</strong> including the soundness of the control gear and switchgear, wire earthing and bonding and conditions of sockets.</p>
<p><strong>Find the damaged appliances that may cause shocks and fires:</strong> Our EICR will determine which device is damaged and recommend repairing and replacing items.</p>
<p><strong>Document the state of an electrical installation for future inspections:</strong> The EICR is also convenient if an accident happens. It's evidence that you have carried out essential measures to guarantee the protection of people.</p>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
