import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'

AOS.init({duration:2000});

export default function PowerShower() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center inner-page-header">
          <div className="col-md-8">
                <h1>Power Shower</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
       <p>People often face issues in the power shower. If you don't know, a power shower is a mixer of regular and electric showers. These pumped showers offer a middle ground solution for houses with low water pressure. The resolution of any problem in your power shower system is just one call away. Our local electricians can repair all models of power showers.</p>

<h2>The benefits of an electric shower</h2>
<p>Electric showers are an excellent alternative for several reasons. From the user’s viewpoint, they make hot water fast with just the press of a button or the turn of a dial.
An electric shower casing has various entry points for cabling and pipework. They can usually work with the current fittings in your bathroom. From a tradesperson’s viewpoint– they’re also largely more manageable to work with than many alternatives!
This ease of fitting lowers the required amount of work–including disorganised and prolonged plastering, tiling and other adjustments. For this reason, electric showers are a fast way to get your bathroom up and running.</p>
<p>An electric shower utilises less water and slighter energy to heat than a bathtub. You can install the shower on the wall above the bathtub if you don’t want to give up your long hot bathtime soak.</p>
<p>An electric shower utilises the household cold water mains for supplying water in the bathroom. You can call our electricians to deal with power showers, who will manage everything like installation, repair, and maintenance. 
</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/power-shower.webp" alt="Power Shower"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
