import SiteButton from '../../sitebutton/SiteButton';
import Calltoaction from '../calltoaction/Calltoaction';
import './about.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

AOS.init({duration:2000});


export default function About() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="about-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
              <h1>About Us</h1>
              <div className="herobutton">
          <SiteButton/>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div id="about-detail" data-aos="zoom-in">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className='sub-heading'>Why Us?</h2>
            <h2>
            Install, Repair, Replace & More
            </h2>
          </div>
          <div className="col-md-6">
<p>Galaxy Electrician Services Ltd. is a locally owned electrical services provider that offers qualified and experienced electricians. Our services are started with a single aim to supply easy, affordable and reachable electrical services on a single platform which is why we have diverse skills. Our team is fully equipped with modern technology to deliver routine, urgent and emergency electrical solutions.</p>
<p>We cover Surrey, Hampshire, Berkshire, and South West of London to deliver premium, swift and high-quality services, 24/7. While working, we examine and solve major commercial and residential electrical issues within the first hour. With a long history of providing outstanding results at an affordable price to our customers.</p>
<div className="about-link">
<SiteButton/>
</div>
          </div>
          <div className="col-md-6">
<p>Galaxy Electrical Services Ltd. issues electrical safety certificates to owners of rented properties in Surrey, Hampshire, Berkshire, South West of London and surrounding areas.
As a landlord, you are required by law to ensure that your property's electrical equipment is safe to use. You are also obliged to ensure that your property is free of fire hazards and that measures are in place to protect your tenants in the event that a fire does break out. Tests should be done at regular intervals to ensure that your property meets all the safety requirements.</p>
<p>Galaxy Electrical Services Ltd is a campaign that aims to reduce deaths and injuries caused by electricity in homes and offices. We do this by working closely with the Government, the electrical industry, manufacturers, retailers, and consumer safety organisations to improve safety regulations and standards. We run media and digital campaigns to share advice and guidance with consumers that will help them to stay safe in their homes.</p>
<p>We are a company purely recognised for its simplicity and customer satisfaction. The wants and the needs of our customers are very important to us. Alternatively, we made the decision to build an easier and more customer-focused way of increasing the amount of EV installs.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="about-certification" data-aos="zoom-in-up">
      <div className="container">
        <div className="row align-items-center" data-aos="zoom-in-down">
          <div className="col-md-4">
            <h2>Certified Electricians</h2>
            <p>We are NICEIC approved and experienced in all domestic, commercial and industrial environments.</p>
          </div>
          <div className="col-md-8">
            <img className="rounded" src='./images/certified-electrician.jpg' alt="certified electrician"/>
          </div>
        </div>
        <div className="row align-items-center mt-5"  data-aos="zoom-in-down">
          <div className="col-md-8">
            <img className="rounded" src='./images/dedicated-team.jpg' alt="Dedicated Team"/>
          </div>
          <div className="col-md-4">
            <h2>Dedicated Team</h2>
            <p>We are an experienced and dedicated team. Fully qualified, trained, and NICEIC approved electricians.</p>
          </div>
        </div>
      </div>
    </div>
    <Calltoaction/>
    </>
  )
}
