import React from 'react'
import SiteButton from '../../sitebutton/SiteButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react';

AOS.init({duration:2000});


export default function DomesticAndCommercialEpc() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className="single-service">
      <div className="container">
      <div className="row  align-items-center  inner-page-header">
          <div className="col-md-8">
                <h1>Domestic and Commercial EPC</h1>
          </div>
          <div className="col-md-4 text-end">
            <div className="link-area">
              <SiteButton/>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-7" data-aos="zoom-in">
        <p>Energy Performance Certificates (EPCs) are needed. You must order an EPC for potential buyers and tenants before you market your property to sell or rent. We can help you! Our experienced electricians can make your property safe and approved.</p>
        </div>
        <div className="col-md-5" data-aos="zoom-in-down">
          <img src="/images/domesticandcommercial.jpg" alt="Appliances Installation"/>
          </div>
        </div>
      </div>

    </div>
    </>
  )
}
