import React from 'react'
import './contact.css'
import axios from 'axios'
import { useState } from 'react'
 
    export default function ContactForm() {

      const [mailSuccess, setMailSuccess] = useState(false)
      const [mailerState, setMailerState] = useState({
        name:"",
        email:"",
        phone:"",
        message: "",
        error: null
      })

      function handleStateChange(e) {
        setMailerState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,

        }));
      }
      // const submitEmail = async (e) => {
      //   e.preventDefault();
      //   console.log({ mailerState });
      //   const response = await fetch("http://localhost:3001/send", {
      //     method: "POST",
      //     headers: {
      //       "Content-type": "application/json",
      //     },
      //     body: JSON.stringify({ mailerState }),
      //   })
      //     .then((res) => res.json())
      //     .then(async (res) => {
      //       const resData = await res;
      //       console.log(resData);
      //       if (resData.status === "success") {
      //         alert("Message Sent");
      //       } else if (resData.status === "fail") {
      //         alert("Message failed to send");
      //       }
      //     })
      //     .then(() => {
      //       setMailerState({
      //         email: "",
      //         phone: "",
      //         name: "",
      //         message: "",
      //       });
      //     });
      // };
      const API_PATH = 'https://galaxyelectricalservicesltd.co.uk/galaxy-api/index.php';
      const submitEmail = async (e) => {
        console.log(mailerState);
        e.preventDefault();
        axios({
          method: 'post',
          url: `${API_PATH}`,
          headers: { 'content-type': 'application/json' },
          data: mailerState
        })
        
          .then(data => {
            setMailerState({
              name:"",
              email:"",
              phone:"",
              message: ""
            });
            if(data.status === 200)
            {
              
              setMailSuccess( data.data.sent)
            }
            console.log('Not sent');
            
            
          })
          //.catch(error => mailerState.error: error.message);
      };
        
          
        return (
        <>
        <div className="container mt-5">
      <form onSubmit={submitEmail}>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input className="form-control" type="text" name="name" id="name" value={mailerState.name} required onChange={handleStateChange} />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input className="form-control" type="email" name="email" value={mailerState.email} id="email" required onChange={handleStateChange} />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Phone number
          </label>
          <input className="form-control" type="tel" name="phone" value={mailerState.phone} id="email" required onChange={handleStateChange} />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea className="form-control" name="message" value={mailerState.message} id="message" required onChange={handleStateChange} />
        </div>
        <button className="btn btn-danger mt-4" type="submit">Submit</button>
      </form>
      {mailSuccess &&
    <div className='successMessage mt-4'>Thank you for contacting us. We will contact you soon.</div>
  }
    </div>
        </>
      )
    }
    